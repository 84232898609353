import {
    GlobalAction,
    CLEAR_GEO_LOCATION,
    PERMANENTLY_DISMISS_STICKY,
    UPDATE_ANY_LEAD_POPUP_SHOWN,
    UPDATE_CALL_SERVICE,
    UPDATE_COUNTY,
    UPDATE_FILTER,
    UPDATE_GEO_LOCATION,
    UPDATE_MULTI_COUNTY_LIST,
    UPDATE_NTM_ACTIVE_STEP,
    UPDATE_NTM_VISITED,
    UPDATE_PHONE,
    UPDATE_PLAN_TYPE,
    UPDATE_TRACKING_PARAMS,
    UPDATE_ZIP_AND_COUNTY,
    UPDATE_ZIP_MODAL_CTA,
    UPDATE_ZIP_MODAL_DESTINATION,
    UPDATE_ZIP_MODAL_HEADING,
} from '../Actions/GlobalActions';
import initialState from '../InitialState/GlobalInitialState';

const reducer = (state = initialState, action: GlobalAction): typeof initialState => {
    switch (action.type) {
        case UPDATE_ANY_LEAD_POPUP_SHOWN:
            return {
                ...state,
                hasAnyLeadPopupShown: true,
            };
        case UPDATE_CALL_SERVICE:
            return {
                ...state,
                ...action.payload,
            };
        case UPDATE_FILTER:
            return {
                ...state,
                filter: action.payload,
            };
        case UPDATE_NTM_ACTIVE_STEP:
            return {
                ...state,
                ntmActiveStep: action.payload,
            };
        case UPDATE_NTM_VISITED:
            return {
                ...state,
                ntmVisited: [...state.ntmVisited, action.payload] as typeof initialState.ntmVisited,
            };
        case UPDATE_PHONE:
            return {
                ...state,
                phone: action.payload as typeof initialState.phone,
            };
        case UPDATE_PLAN_TYPE:
            return {
                ...state,
                planType: action.payload,
            };
        case UPDATE_TRACKING_PARAMS:
            return {
                ...state,
                trackingParams: {
                    ...state.trackingParams,
                    ...action.payload,
                } as typeof initialState.trackingParams,
            };
        case UPDATE_COUNTY:
            return {
                ...state,
                geoLocation: {
                    zip: state.geoLocation.zip,
                    countyName: action.payload,
                },
            };
        case UPDATE_ZIP_AND_COUNTY:
            return {
                ...state,
                geoLocation: {
                    zip: action.payload.zip,
                    countyName: action.payload.countyName,
                },
            };
        case UPDATE_GEO_LOCATION:
            return {
                ...state,
                geoLocation: {
                    ...action.payload,
                    ssacd: action.payload.ssacd?.replace(/^0/, ''),
                },
            };
        case UPDATE_MULTI_COUNTY_LIST:
            return {
                ...state,
                multiCountyList: [...action.payload],
            };
        case PERMANENTLY_DISMISS_STICKY:
            return {
                ...state,
                hideDismissableStickyPermanently: true,
            };
        case CLEAR_GEO_LOCATION:
            return {
                ...state,
                geoLocation: { zip: '', countyName: '' },
            };
        case UPDATE_ZIP_MODAL_CTA:
            return {
                ...state,
                zipModalConfig: {
                    ...state.zipModalConfig,
                    customCta: action.payload,
                },
            };
        case UPDATE_ZIP_MODAL_DESTINATION:
            return {
                ...state,
                zipModalConfig: {
                    ...state.zipModalConfig,
                    customDestination: action.payload,
                },
            };
        case UPDATE_ZIP_MODAL_HEADING:
            return {
                ...state,
                zipModalConfig: {
                    ...state.zipModalConfig,
                    customHeading: action.payload || '',
                },
            };
        default:
            return state;
    }
};
export default reducer;
