export const CLEAR_GEO_LOCATION = 'CLEAR_GEO_LOCATION';
export const PERMANENTLY_DISMISS_STICKY = 'PERMANENTLY_DISMISS_STICKY';
export const UPDATE_ANY_LEAD_POPUP_SHOWN = 'UPDATE_ANY_LEAD_POPUP_SHOWN';
export const UPDATE_CALL_SERVICE = 'UPDATE_CALL_SERVICE';
export const UPDATE_COUNTY = 'UPDATE_COUNTY';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_GEO_LOCATION = 'UPDATE_GEO_LOCATION';
export const UPDATE_MULTI_COUNTY_LIST = 'UPDATE_MULTI_COUNTY_LIST';
export const UPDATE_NTM_ACTIVE_STEP = 'UPDATE_NTM_ACTIVE_STEP';
export const UPDATE_NTM_VISITED = 'UPDATE_NTM_VISITED';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const UPDATE_PLAN_TYPE = 'UPDATE_PLAN_TYPE';
export const UPDATE_TRACKING_PARAMS = 'UPDATE_TRACKING_PARAMS';
export const UPDATE_ZIP_AND_COUNTY = 'UPDATE_ZIP_AND_COUNTY';
export const UPDATE_ZIP_MODAL_CTA = 'UPDATE_ZIP_MODAL_CTA';
export const UPDATE_ZIP_MODAL_DESTINATION = 'UPDATE_ZIP_MODAL_DESTINATION';
export const UPDATE_ZIP_MODAL_HEADING = 'UPDATE_ZIP_MODAL_HEADING';

export type GlobalAction =
    | ReturnType<typeof clearGeoLocation>
    | ReturnType<typeof permanentlyDismissStickyCta>
    | ReturnType<typeof updateAnyLeadPopupShown>
    | ReturnType<typeof updateCallService>
    | ReturnType<typeof updateCounty>
    | ReturnType<typeof updateFilter>
    | ReturnType<typeof updateGeoLocation>
    | ReturnType<typeof updateMultiCountyList>
    | ReturnType<typeof updateNtmActiveStep>
    | ReturnType<typeof updateNtmVisited>
    | ReturnType<typeof updatePhone>
    | ReturnType<typeof updatePlanType>
    | ReturnType<typeof updateTrackingParams>
    | ReturnType<typeof updateZipAndCounty>
    | ReturnType<typeof updateZipModalCta>
    | ReturnType<typeof updateZipModalDestination>
    | ReturnType<typeof updateZipModalHeading>;

export const updateAnyLeadPopupShown = (): { type: 'UPDATE_ANY_LEAD_POPUP_SHOWN' } => {
    return {
        type: UPDATE_ANY_LEAD_POPUP_SHOWN,
    };
};

export const updateCallService = (data: {
    allianceId: string;
    companyUrl: string;
    hours: string;
    lightModeHeader: boolean;
    logo: string;
    partnerName: string;
    partnershipType: string;
    segmentType: string;
    suppressEmailCapturePopup: boolean;
    tcpaDisclaimer: string;
    throttle: IThrottle;
}): { type: 'UPDATE_CALL_SERVICE'; payload: typeof data } => {
    return {
        type: UPDATE_CALL_SERVICE,
        payload: data,
    };
};

export const updateFilter = (data: string): { type: 'UPDATE_FILTER'; payload: typeof data } => {
    return {
        type: UPDATE_FILTER,
        payload: data,
    };
};

export const updateNtmActiveStep = (
    data: string
): { type: 'UPDATE_NTM_ACTIVE_STEP'; payload: typeof data } => {
    return {
        type: UPDATE_NTM_ACTIVE_STEP,
        payload: data,
    };
};

export const updateNtmVisited = (
    data: string
): { type: 'UPDATE_NTM_VISITED'; payload: typeof data } => {
    return {
        type: UPDATE_NTM_VISITED,
        payload: data,
    };
};

export const updatePhone = (data: {
    number: string;
    isFinal: boolean;
}): { type: 'UPDATE_PHONE'; payload: typeof data } => {
    return {
        type: UPDATE_PHONE,
        payload: data,
    };
};

export const updatePlanType = (
    data: PlansType
): { type: 'UPDATE_PLAN_TYPE'; payload: typeof data } => {
    return {
        type: UPDATE_PLAN_TYPE,
        payload: data,
    };
};

export const updateTrackingParams = (
    data: Record<string, string>
): { type: 'UPDATE_TRACKING_PARAMS'; payload: typeof data } => {
    return {
        type: UPDATE_TRACKING_PARAMS,
        payload: data,
    };
};

export const updateCounty = (data: string): { type: 'UPDATE_COUNTY'; payload: typeof data } => {
    return {
        type: UPDATE_COUNTY,
        payload: data,
    };
};

export const updateZipAndCounty = (
    zip: string,
    countyName: string
): { type: 'UPDATE_ZIP_AND_COUNTY'; payload: { zip: string; countyName: string } } => {
    return {
        type: UPDATE_ZIP_AND_COUNTY,
        payload: { zip, countyName },
    };
};

export const updateGeoLocation = (
    data: GeoLocation
): { type: 'UPDATE_GEO_LOCATION'; payload: typeof data } => {
    return {
        type: UPDATE_GEO_LOCATION,
        payload: data,
    };
};

export const updateMultiCountyList = (
    data: GeoLocation[]
): { type: 'UPDATE_MULTI_COUNTY_LIST'; payload: typeof data } => {
    return {
        type: UPDATE_MULTI_COUNTY_LIST,
        payload: data,
    };
};

export const clearGeoLocation = (): { type: 'CLEAR_GEO_LOCATION' } => {
    return {
        type: CLEAR_GEO_LOCATION,
    };
};

export const permanentlyDismissStickyCta = (): { type: 'PERMANENTLY_DISMISS_STICKY' } => {
    return {
        type: PERMANENTLY_DISMISS_STICKY,
    };
};

export const updateZipModalCta = (
    data: string
): { type: 'UPDATE_ZIP_MODAL_CTA'; payload: typeof data } => {
    return {
        type: UPDATE_ZIP_MODAL_CTA,
        payload: data,
    };
};

export const updateZipModalDestination = (
    data: string
): { type: 'UPDATE_ZIP_MODAL_DESTINATION'; payload: typeof data } => {
    return {
        type: UPDATE_ZIP_MODAL_DESTINATION,
        payload: data,
    };
};

export const updateZipModalHeading = (
    data?: string
): { type: 'UPDATE_ZIP_MODAL_HEADING'; payload: typeof data } => {
    return {
        type: UPDATE_ZIP_MODAL_HEADING,
        payload: data,
    };
};
