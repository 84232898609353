export const CLEAR_SESSION_AND_USER = 'CLEAR_SESSION_AND_USER';
export const UPDATE_CC_ACTION = 'UPDATE_CC_ACTION';
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const UPDATE_LEAD_FROM_APP_SESSION = 'UPDATE_LEAD_FROM_APP_SESSION';
export const UPDATE_LEAD_FROM_APP_SESSION_FAILED = 'UPDATE_LEAD_FROM_APP_SESSION_FAILED';
export const UPDATE_SAVED_SESSION = 'UPDATE_SAVED_SESSION';
export const UPDATE_USER = 'UPDATE_USER';

export type UserAction =
    | ReturnType<typeof clearSessionAndUser>
    | ReturnType<typeof updateCcAction>
    | ReturnType<typeof updateFavorites>
    | ReturnType<typeof updateLead>
    | ReturnType<typeof updateLeadFromAppSession>
    | ReturnType<typeof updateLeadFromAppSessionFailed>
    | ReturnType<typeof updateSavedSession>
    | ReturnType<typeof updateUser>;

export const clearSessionAndUser = (): { type: 'CLEAR_SESSION_AND_USER' } => {
    return {
        type: CLEAR_SESSION_AND_USER,
    };
};

export const updateSavedSession = (
    data: ISavedSession
): { type: 'UPDATE_SAVED_SESSION'; payload: typeof data } => {
    return {
        type: UPDATE_SAVED_SESSION,
        payload: data,
    };
};

export const updateUser = (data: {
    isLoggedIn: boolean;
    data: IUserData;
}): { type: 'UPDATE_USER'; payload: typeof data } => {
    return {
        type: UPDATE_USER,
        payload: data,
    };
};

export const updateFavorites = (
    data: number
): { type: 'UPDATE_FAVORITES'; payload: typeof data } => {
    return {
        type: UPDATE_FAVORITES,
        payload: data,
    };
};

export const updateCcAction = (
    data: string
): { type: 'UPDATE_CC_ACTION'; payload: typeof data } => {
    return {
        type: UPDATE_CC_ACTION,
        payload: data,
    };
};

export const updateLead = (
    leadId: string,
    medicareSessionId: string
): { type: 'UPDATE_LEAD'; payload: { leadId: string; medicareSessionId: string } } => {
    return {
        type: UPDATE_LEAD,
        payload: { leadId, medicareSessionId },
    };
};

export const updateLeadFromAppSession = (data: {
    medicareSessionId?: string;
    isCCUser: boolean;
    leadStatus: string;
    email: string;
    userStatus: LeadData['userStatus'];
    didRequestSucceed: boolean;
}): { type: 'UPDATE_LEAD_FROM_APP_SESSION'; payload: typeof data } => {
    return {
        type: UPDATE_LEAD_FROM_APP_SESSION,
        payload: data,
    };
};

export const updateLeadFromAppSessionFailed = (data: {
    email: string;
}): {
    type: 'UPDATE_LEAD_FROM_APP_SESSION_FAILED';
    payload: typeof data;
} => {
    return {
        type: UPDATE_LEAD_FROM_APP_SESSION_FAILED,
        payload: data,
    };
};
