import { createStore, combineReducers } from 'redux';

import displayInitialState from './InitialState/DisplayInitialState';
import globalInitialState from './InitialState/GlobalInitialState';
import userInitialState from './InitialState/UserInitialState';
import DisplayReducer from './Reducers/DisplayReducer';
import GlobalReducer from './Reducers/GlobalReducer';
import UserReducer from './Reducers/UserReducer';
import { loadState, saveState, throttle } from './persistState';

const rootReducer = combineReducers({
    global: GlobalReducer,
    display: DisplayReducer,
    user: UserReducer,
});

const persistedState = loadState();

const initialState = {
    global: { ...globalInitialState, ...persistedState?.global },
    display: { ...displayInitialState, ...persistedState?.display },
    user: { ...userInitialState, ...persistedState?.user },
};

const store = createStore(rootReducer, initialState);

/*
// development settings allowing devtools to read store
const store = createStore(
    rootReducer,
    initialState,
    process.env.GATSBY_EH_ENV === 'local' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/

store.subscribe(
    throttle(() => {
        saveState(store.getState());
    }, 500)
);

export type IRootState = ReturnType<typeof rootReducer>;

export default store;
