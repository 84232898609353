import { setCookie } from '~globals/common';

import { IRootState } from './store';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('ehState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState) as IRootState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: IRootState) => {
    try {
        const savedState = {
            global: state.global,
        };
        const serializedState = JSON.stringify(savedState);
        localStorage.setItem('ehState', serializedState);
    } catch (err) {
        if (typeof window !== 'undefined') {
            console.log(err);
        }
    }
};

export const throttle = (callback: () => void, limit: number) => {
    let waiting = false;
    return function () {
        if (!waiting) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            callback.apply(this, arguments);
            waiting = true;
            setTimeout(function () {
                waiting = false;
            }, limit);
        }
    };
};
