import constants from '~globals/constants';

const initialState = {
    allianceId: '',
    companyUrl: '',
    fbclid: '', // TODO never assigned a value
    filter: '',
    gclid: '', // TODO never assigned a value
    geoLocation: { countyName: '', zip: '' } as GeoLocation,
    hasAnyLeadPopupShown: false,
    hideDismissableStickyPermanently: false,
    hours: '',
    isCCUser: false,
    lastUpdated: '', // TODO never assigned a value
    lightModeHeader: false,
    logo: '',
    mktCode: '', // TODO never assigned a value
    msclid: '', // TODO never assigned a value
    multiCountyList: [] as GeoLocation[],
    ntmActiveStep: '',
    ntmVisited: [] as string[],
    partnerName: '',
    partnershipType: '',
    phone: { number: constants.DEFAULT_PHONE as string, isFinal: false },
    planType: '' as PlansType,
    planYear: new Date().getFullYear(),
    segmentType: '',
    suppressEmailCapturePopup: false,
    tcpaDisclaimer: '',
    throttle: '' as IThrottle,
    trackingParams: {} as TrackingParameters,
    zipModalConfig: {
        customDestination: process.env.GATSBY_EHMP_URL,
        customHeading: '',
        customCta: '',
    },
};

export default initialState;
