import {
    CLEAR_SESSION_AND_USER,
    UPDATE_SAVED_SESSION,
    UPDATE_USER,
    UPDATE_FAVORITES,
    UPDATE_CC_ACTION,
    UPDATE_LEAD,
    UPDATE_LEAD_FROM_APP_SESSION,
    UserAction,
    UPDATE_LEAD_FROM_APP_SESSION_FAILED,
} from '../Actions/UserActions';
import initialState from '../InitialState/UserInitialState';

const reducer = (state = initialState, action: UserAction): typeof initialState => {
    switch (action.type) {
        case CLEAR_SESSION_AND_USER:
            return {
                ...state,
                savedSession: {
                    sessionCode: '',
                    medications: undefined,
                    providers: undefined,
                },
                user: {
                    isLoggedIn: false,
                    data: {},
                },
                planFavorites: 0,
                ccAction: '',
            };
        case UPDATE_SAVED_SESSION:
            return {
                ...state,
                savedSession: action.payload,
            };
        case UPDATE_USER:
            return {
                ...state,
                user: action.payload as typeof initialState.user,
            };
        case UPDATE_FAVORITES:
            return {
                ...state,
                planFavorites: action.payload,
            };
        case UPDATE_CC_ACTION:
            return {
                ...state,
                ccAction: action.payload,
            };
        case UPDATE_LEAD:
            return {
                ...state,
                lead: {
                    leadId: action.payload.leadId,
                    medicareSessionId: action.payload.medicareSessionId,
                },
            };
        case UPDATE_LEAD_FROM_APP_SESSION:
            return {
                ...state,
                lead: {
                    email: action.payload.email,
                    isCCUser: action.payload.isCCUser,
                    leadId: '',
                    leadStatus: action.payload.leadStatus,
                    medicareSessionId: action.payload.medicareSessionId,
                    userStatus: action.payload.userStatus,
                    didRequestSucceed: action.payload.didRequestSucceed,
                },
            };
        case UPDATE_LEAD_FROM_APP_SESSION_FAILED:
            return {
                ...state,
                lead: {
                    ...(state.lead.email === action.payload.email && state.lead),
                    email: action.payload.email,
                    didRequestSucceed: false,
                },
            };

        default:
            return state;
    }
};
export default reducer;
