import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PageDataProvider } from '~components/PageDataProvider/PageDataProvider';
import { RouterLocation } from '~globals/interfaces';

import ThirdPartyResources from './third-party-resources';

const queryClient = new QueryClient();

type PageContextData = {
    element: React.ReactNode;
    props: {
        location: RouterLocation;
        pageContext: PageCreationContext;
        data:
            | { wpCategory: IWpCategory; allWpPost: IWpPost[] }
            | { wpPage: IWpPage }
            | { wpPost: IWpPost };
    };
};

const Wrapper = (pageData: PageContextData) => {
    let ppccPageCode = '';
    let seo: WpSeo | undefined = undefined;
    let slug = '';
    let tags: { name: string; slug: string }[] = [];
    let templateName = '';

    const { data } = pageData.props;

    if (data) {
        if ('wpPost' in data) {
            seo = data.wpPost.seo;
            slug = data.wpPost.slug;
            tags = data.wpPost.tags?.nodes;
        } else if ('wpPage' in data) {
            ppccPageCode = data.wpPage.acfPage?.ppccPageCode || '';
            seo = data.wpPage.seo;
            slug = data.wpPage.slug;
            templateName = data.wpPage.template?.templateName;
        } else {
            seo = data.wpCategory.seo;
            slug = data.wpCategory.slug;
        }
    } else {
        // for pages hard-coded, there is no query data
        const pathnameParts = pageData.props.location.pathname.split('/').filter(str => !!str);
        slug = pathnameParts[pathnameParts.length - 1];
    }

    return (
        <>
            <ThirdPartyResources />
            <QueryClientProvider client={queryClient}>
                <PageDataProvider
                    annualCostData={pageData.props.pageContext.annualCostData}
                    location={pageData.props.location}
                    ppccPageCode={ppccPageCode}
                    seo={seo}
                    slug={slug}
                    tags={tags}
                    templateName={templateName}
                    isEligibileForAbTesting={pageData.props.pageContext.isEligibileForAbTesting}
                >
                    {pageData.element}
                </PageDataProvider>
            </QueryClientProvider>
        </>
    );
};

export default Wrapper;
