const initialState = {
    savedSession: {
        sessionCode: '',
        medications: undefined,
        providers: undefined,
    } as ISavedSession,
    user: {
        isLoggedIn: false,
        data: {} as IUserData,
    },
    lead: {} as LeadData,
    planFavorites: 0,
    ccAction: '',
};
export default initialState;
