import { IPageContext } from './PageDataProvider';

// annualCostData: IFeatureFlag;
// location?: RouterLocation;
// slug: string;
// templateName: string;
// tags: { name: string; slug: string }[];
// ppccPageCode: string;
// seo?: WpSeo;

export const testPageData: IPageContext = {
    templateName: 'Default',
    tags: [],
    seo: {
        title: 'Compare, Shop & Enroll in Medicare Plans Online | eHealth',
        metaDesc:
            'Free no obligation quote and compare Medicare Insurance Plans including Advantage (Part C), Prescription Drug (Part D), and Supplement (Medigap) plans.',
        focuskw: '',
        metaKeywords: '',
        metaRobotsNoindex: 'index',
        metaRobotsNofollow: 'follow',
        opengraphTitle: 'Home Page',
        opengraphDescription:
            'Free no obligation quote and compare Medicare Insurance Plans including Advantage (Part C), Prescription Drug (Part D), and Supplement (Medigap) plans.',
        twitterTitle: '',
        twitterDescription: '',
        twitterImage: null,
        cornerstone: false,
        schema: {
            articleType: [],
            pageType: ['WebPage'],
            raw: '{"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"/","url":"/","name":"Compare, Shop & Enroll in Medicare Plans Online | eHealth","isPartOf":{"@id":"/#website"},"datePublished":"2022-06-02T14:37:10+00:00","dateModified":"2022-06-06T14:06:06+00:00","description":"Free no obligation quote and compare Medicare Insurance Plans including Advantage (Part C), Prescription Drug (Part D), and Supplement (Medigap) plans.","breadcrumb":{"@id":"/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["/"]}]},{"@type":"BreadcrumbList","@id":"/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Home"}]},{"@type":"WebSite","@id":"/#website","url":"/","name":"eHealthMedicare","description":"","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"}]}',
        },
    },
    location: {
        ancestorOrigins: {
            length: 0,
            contains: (a: string) => !!a,
            item: (index: number) => 'string'[index],
            [Symbol.iterator]: () => Object.getPrototypeOf(Object.getPrototypeOf([].values())),
        },
        hash: '',
        host: 'localhost:8000',
        hostname: 'localhost',
        href: 'http://localhost:8000/new-to-medicare',
        origin: 'http://localhost:8000',
        pathname: '/new-to-medicare',
        port: '8000',
        protocol: 'http:',
        search: '',
        assign: () => null,
        reload: () => null,
        replace: () => null,
        state: {},
    },
    slug: 'home-page',
    annualCostData: {
        uid: 'annualCostData.2021',
        description: 'The annual cost data for 2021 (based on AEP 2020)',
        group: 'ACD',
        flippingStrategy: null,
        permissions: [],
        customProperties: {
            PART_A_HOSP_COINS_DAYS_91_UP: {
                name: 'PART_A_HOSP_COINS_DAYS_91_UP',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$742',
                fixedValues: [],
            },
            AVERAGE_SAVINGS_DISCLAIMER: {
                name: 'AVERAGE_SAVINGS_DISCLAIMER',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: "Based on more than 33,000 user sessions of eHealth's prescription drug coverage comparison tool by Medicare customers during Medicare's Annual Enrollment Period for 2021 coverage, which took place between October 15 and December 7, 2020.",
                fixedValues: [],
            },
            QMB_INDIV_RESOURCE_LIMIT: {
                name: 'QMB_INDIV_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$7,970',
                fixedValues: [],
            },
            MS_L_OOP_MAX: {
                name: 'MS_L_OOP_MAX',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$3,110',
                fixedValues: [],
            },
            IRMAA_MARRIED_SEP_LINE_1: {
                name: 'IRMAA_MARRIED_SEP_LINE_1',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$88,000',
                fixedValues: [],
            },
            PART_A_DEDUCTIBLE: {
                name: 'PART_A_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,484',
                fixedValues: [],
            },
            QI_MARRIED_MONTHLY_INCOME_LIMIT: {
                name: 'QI_MARRIED_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,890',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_6: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_6',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$504.90',
                fixedValues: [],
            },
            IRMAA_MARRIED_SEP_LINE_5: {
                name: 'IRMAA_MARRIED_SEP_LINE_5',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$330,001 - under $750,000',
                fixedValues: [],
            },
            CURRENT_YEAR: {
                name: 'CURRENT_YEAR',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '2021',
                fixedValues: [],
            },
            IRMAA_MARRIED_SEP_LINE_6: {
                name: 'IRMAA_MARRIED_SEP_LINE_6',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$750,000',
                fixedValues: [],
            },
            QDWI_MARRIED_MONTHLY_INCOME_LIMIT: {
                name: 'QDWI_MARRIED_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$5,892',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_4: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_4',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$386.10',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_5: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_5',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$475.20',
                fixedValues: [],
            },
            QI_MARRIED_RESOURCE_LIMIT: {
                name: 'QI_MARRIED_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$11,960',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_2: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_2',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$207.90',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_3: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_3',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$297.00',
                fixedValues: [],
            },
            AVERAGE_ANNUAL_SAVINGS: {
                name: 'AVERAGE_ANNUAL_SAVINGS',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$762',
                fixedValues: [],
            },
            IRMAA_PARTB_PREMIUM_LINE_1: {
                name: 'IRMAA_PARTB_PREMIUM_LINE_1',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$148.50',
                fixedValues: [],
            },
            CUSTOMERS_SERVED_DISCLAIMER: {
                name: 'CUSTOMERS_SERVED_DISCLAIMER',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: 'Since 1997, eHealth has connected more than 8 million paid members with health insurance, Medicare options, and ancillary plans.',
                fixedValues: [],
            },
            MA_AVG_DEDUCTIBLE: {
                name: 'MA_AVG_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$129',
                fixedValues: [],
            },
            SLMB_INDIV_MONTHLY_INCOME_LIMIT: {
                name: 'SLMB_INDIV_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,308',
                fixedValues: [],
            },
            QDWI_INDIV_MONTHLY_INCOME_LIMIT: {
                name: 'QDWI_INDIV_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$4,379',
                fixedValues: [],
            },
            AVERAGE_SAVINGS_VISITORS: {
                name: 'AVERAGE_SAVINGS_VISITORS',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '111,000',
                fixedValues: [],
            },
            PREVIOUS_YEAR: {
                name: 'PREVIOUS_YEAR',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '2020',
                fixedValues: [],
            },
            QDWI_MARRIED_RESOURCE_LIMIT: {
                name: 'QDWI_MARRIED_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$6,000',
                fixedValues: [],
            },
            PART_D_AVG_DEDUCTIBLE: {
                name: 'PART_D_AVG_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$405',
                fixedValues: [],
            },
            IRMAA_PARTD_PREMIUM_LINE_3: {
                name: 'IRMAA_PARTD_PREMIUM_LINE_3',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$31.80',
                fixedValues: [],
            },
            QDWI_INDIV_RESOURCE_LIMIT: {
                name: 'QDWI_INDIV_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$4,000',
                fixedValues: [],
            },
            IRMAA_PARTD_PREMIUM_LINE_2: {
                name: 'IRMAA_PARTD_PREMIUM_LINE_2',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$12.30',
                fixedValues: [],
            },
            IRMAA_PARTD_PREMIUM_LINE_5: {
                name: 'IRMAA_PARTD_PREMIUM_LINE_5',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$70.70',
                fixedValues: [],
            },
            PART_B_PREMIUM_COST: {
                name: 'PART_B_PREMIUM_COST',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$148.50',
                fixedValues: [],
            },
            IRMAA_PARTD_PREMIUM_LINE_4: {
                name: 'IRMAA_PARTD_PREMIUM_LINE_4',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$51.20',
                fixedValues: [],
            },
            IRMAA_PARTD_PREMIUM_LINE_6: {
                name: 'IRMAA_PARTD_PREMIUM_LINE_6',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$77.10',
                fixedValues: [],
            },
            MS_HDG_DEDUCTIBLE: {
                name: 'MS_HDG_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$2,370',
                fixedValues: [],
            },
            PART_D_COVERAGE_GAP: {
                name: 'PART_D_COVERAGE_GAP',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$4,130',
                fixedValues: [],
            },
            PART_A_PREMIUM_30_39_QTRS: {
                name: 'PART_A_PREMIUM_30_39_QTRS',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$259',
                fixedValues: [],
            },
            CUSTOMERS_SERVED: {
                name: 'CUSTOMERS_SERVED',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '8 million',
                fixedValues: [],
            },
            SLMB_INDIV_RESOURCE_LIMIT: {
                name: 'SLMB_INDIV_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$7,970',
                fixedValues: [],
            },
            PART_D_PENALTY: {
                name: 'PART_D_PENALTY',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$33.06',
                fixedValues: [],
            },
            PART_B_DEDUCTIBLE: {
                name: 'PART_B_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$203',
                fixedValues: [],
            },
            PART_C_IN_OUT_NETWORK_OOP_MAX: {
                name: 'PART_C_IN_OUT_NETWORK_OOP_MAX',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$11,300',
                fixedValues: [],
            },
            PART_D_CATASTROPH_COVERAGE_PHASE: {
                name: 'PART_D_CATASTROPH_COVERAGE_PHASE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$6,550',
                fixedValues: [],
            },
            PART_A_PREMIUM_LESS_30_QTRS: {
                name: 'PART_A_PREMIUM_LESS_30_QTRS',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$471',
                fixedValues: [],
            },
            TWO_YEARS_AGO: {
                name: 'TWO_YEARS_AGO',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '2019',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_3: {
                name: 'IRMAA_INDIV_LINE_3',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$111,001 - $138,000',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_4: {
                name: 'IRMAA_INDIV_LINE_4',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$138,001 - $165,000',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_1: {
                name: 'IRMAA_INDIV_LINE_1',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$88,000',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_2: {
                name: 'IRMAA_INDIV_LINE_2',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$88,001 - $111,000',
                fixedValues: [],
            },
            PART_D_CATASTROPH_MAX_BRAND: {
                name: 'PART_D_CATASTROPH_MAX_BRAND',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$9.20',
                fixedValues: [],
            },
            PART_C_IN_NETWORK_OOP_MAX: {
                name: 'PART_C_IN_NETWORK_OOP_MAX',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$7,550',
                fixedValues: [],
            },
            MS_K_OOP_MAX: {
                name: 'MS_K_OOP_MAX',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$6,220',
                fixedValues: [],
            },
            QMB_INDIV_MONTHLY_INCOME_LIMIT: {
                name: 'QMB_INDIV_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,094',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_5: {
                name: 'IRMAA_INDIV_LINE_5',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$165,001 - under $500,000',
                fixedValues: [],
            },
            IRMAA_INDIV_LINE_6: {
                name: 'IRMAA_INDIV_LINE_6',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$500,000',
                fixedValues: [],
            },
            QI_INDIV_MONTHLY_INCOME_LIMIT: {
                name: 'QI_INDIV_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,469',
                fixedValues: [],
            },
            SLMB_MARRIED_RESOURCE_LIMIT: {
                name: 'SLMB_MARRIED_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$11,960',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_5: {
                name: 'IRMAA_MARRIED_JOINT_LINE_5',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$330,001 - under $412,000',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_4: {
                name: 'IRMAA_MARRIED_JOINT_LINE_4',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$276,001 - $330,000',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_6: {
                name: 'IRMAA_MARRIED_JOINT_LINE_6',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$412,000',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_1: {
                name: 'IRMAA_MARRIED_JOINT_LINE_1',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$176,000',
                fixedValues: [],
            },
            QMB_MARRIED_MONTHLY_INCOME_LIMIT: {
                name: 'QMB_MARRIED_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,472',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_3: {
                name: 'IRMAA_MARRIED_JOINT_LINE_3',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$222,001 - $276,000',
                fixedValues: [],
            },
            PART_D_MAX_DEDUCTIBLE: {
                name: 'PART_D_MAX_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$445',
                fixedValues: [],
            },
            IRMAA_MARRIED_JOINT_LINE_2: {
                name: 'IRMAA_MARRIED_JOINT_LINE_2',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$176,001 - $222,000',
                fixedValues: [],
            },
            MS_HDF_DEDUCTIBLE: {
                name: 'MS_HDF_DEDUCTIBLE',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$2,370',
                fixedValues: [],
            },
            PART_D_CATASTROPH_MAX_GENERIC: {
                name: 'PART_D_CATASTROPH_MAX_GENERIC',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$3.70',
                fixedValues: [],
            },
            SLMB_MARRIED_MONTHLY_INCOME_LIMIT: {
                name: 'SLMB_MARRIED_MONTHLY_INCOME_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$1,762',
                fixedValues: [],
            },
            PART_A_SNF_COINS: {
                name: 'PART_A_SNF_COINS',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$185.50',
                fixedValues: [],
            },
            PART_A_COINS_DAYS_61_90: {
                name: 'PART_A_COINS_DAYS_61_90',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$371',
                fixedValues: [],
            },
            QI_INDIV_RESOURCE_LIMIT: {
                name: 'QI_INDIV_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$7,970',
                fixedValues: [],
            },
            QMB_MARRIED_RESOURCE_LIMIT: {
                name: 'QMB_MARRIED_RESOURCE_LIMIT',
                description: null,
                type: 'org.ff4j.property.PropertyString',
                value: '$11,960',
                fixedValues: [],
            },
        },
        enable: true,
    },
};
