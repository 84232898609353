import { createContext, useContext } from 'react';

import { RouterLocation } from '~globals/interfaces';

import { testPageData } from './testData';

export type IPageContext = {
    annualCostData: StringMap;
    isEligibileForAbTesting?: boolean;
    location: RouterLocation;
    slug: string;
    templateName: string;
    tags: { name: string; slug: string }[];
    ppccPageCode?: string;
    seo?: WpSeo;
};

const Context = createContext(null as null | IPageContext);

export const usePageContext = () => {
    const contextState = useContext(Context);
    if (contextState === null) {
        throw new Error('usePageContext must be used within a PageDataProvider tag');
    }
    return contextState;
};

export const PageDataProvider = (props: React.PropsWithChildren<IPageContext>) => {
    return <Context.Provider value={props}>{props.children}</Context.Provider>;
};

export const TestPageDataProvider = (props: React.PropsWithChildren<IPageContext>) => {
    return <Context.Provider value={testPageData}>{props.children}</Context.Provider>;
};
