const initialState = {
    adobeTesting: {
        adobeHasLoaded: false,
        adobeContentRenderingStart: false,
        adobeTestData: {
            tools: 'a',
            personalcode: 'a',
        },
        adobeExperiences: [],
    } as AdobeTestingData,
    personalCode: {
        errorType: '',
        modalOpen: false,
        modalInfo: {
            title: '',
            message: '',
        },
        redirectParams: {} as { type: string; target: string },
    },
    mobileNavMenuOpen: false,
    phoneMenuOpen: false,
    stickyGuideOpen: false,
    subMenuOpen: '' as ISubMenu,
    zipModalOpen: false,
    isSessionLookupResultsModalOpen: false,
    isRetrieveWorkModalOpen: false,
};
export default initialState;
