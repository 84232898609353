import {
    CLOSE_MENUS,
    CLOSE_MOBILENAVMENU,
    CLOSE_PHONEMENU,
    CLOSE_SUBMENU,
    DisplayAction,
    OPEN_MOBILENAVMENU,
    OPEN_PHONEMENU,
    OPEN_SUBMENU,
    TOGGLE_RETRIEVE_SESSION_MODAL,
    TOGGLE_RETRIEVE_WORK_MODAL,
    TOGGLE_STICKY_EMAIL_GUIDE,
    TOGGLE_ZIPMODAL,
    UPDATE_ADOBE_TESTING,
    UPDATE_PC_CENSUS,
} from '../Actions/DisplayActions';
import initialState from '../InitialState/DisplayInitialState';

const reducer = (state = initialState, action: DisplayAction): typeof initialState => {
    switch (action.type) {
        case OPEN_MOBILENAVMENU:
            return {
                ...state,
                subMenuOpen: '',
                phoneMenuOpen: false,
                mobileNavMenuOpen: true,
            };
        case CLOSE_MOBILENAVMENU:
            return {
                ...state,
                subMenuOpen: '',
                mobileNavMenuOpen: false,
            };
        case OPEN_PHONEMENU:
            return {
                ...state,
                subMenuOpen: '',
                mobileNavMenuOpen: false,
                phoneMenuOpen: true,
            };
        case CLOSE_PHONEMENU:
            return {
                ...state,
                subMenuOpen: '',
                phoneMenuOpen: false,
            };
        case OPEN_SUBMENU:
            return {
                ...state,
                subMenuOpen: action.payload as typeof initialState.subMenuOpen,
                mobileNavMenuOpen: false,
            };
        case CLOSE_SUBMENU:
            return {
                ...state,
                subMenuOpen: '',
            };
        case CLOSE_MENUS:
            return {
                ...state,
                mobileNavMenuOpen: false,
                phoneMenuOpen: false,
                subMenuOpen: '',
            };
        case TOGGLE_STICKY_EMAIL_GUIDE:
            return {
                ...state,
                stickyGuideOpen: action.payload,
            };
        case TOGGLE_ZIPMODAL:
            return {
                ...state,
                zipModalOpen: action.payload,
            };
        case UPDATE_ADOBE_TESTING:
            return {
                ...state,
                adobeTesting: action.payload,
            };
        case UPDATE_PC_CENSUS:
            return {
                ...state,
                personalCode: action.payload,
            };
        case TOGGLE_RETRIEVE_SESSION_MODAL:
            return {
                ...state,
                isSessionLookupResultsModalOpen: action.payload,
            };
        case TOGGLE_RETRIEVE_WORK_MODAL:
            return {
                ...state,
                isRetrieveWorkModalOpen: action.payload,
            };
        default:
            return state;
    }
};
export default reducer;
