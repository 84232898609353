import { setGlobalTokens } from '@ehi/styles';

import './src/css/index.css';
import './src/css/template-sutter.css';

import wrapWithPageData from './wrap-with-pagedata';
import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithPageData;

setGlobalTokens();

const addScript = () => {
    const globalScripts = document.createElement('script');
    globalScripts.innerHTML = `!function (e, v, o) { e[v] || (e[v] = [], e[v].x = e[v].push, e[v].push = function (a, b) { this.x([a, b]) }) }(window, "_waEvents");`;
    document.head.appendChild(globalScripts);
};

export const onClientEntry = () => {
    window.onload = () => {
        addScript();
    };
};
