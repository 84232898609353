export const OPEN_MOBILENAVMENU = 'OPEN_MOBILENAVMENU';
export const CLOSE_MOBILENAVMENU = 'CLOSE_MOBILENAVMENU';

export const OPEN_PHONEMENU = 'OPEN_PHONEMENU';
export const CLOSE_PHONEMENU = 'CLOSE_PHONEMENU';

export const OPEN_SUBMENU = 'OPEN_SUBMENU';
export const CLOSE_SUBMENU = 'CLOSE_SUBMENU';

export const CLOSE_MENUS = 'CLOSE_MENUS';

export const TOGGLE_ZIPMODAL = 'TOGGLE_ZIPMODAL';
export const TOGGLE_STICKY_EMAIL_GUIDE = 'TOGGLE_STICKY_EMAIL_GUIDE';

export const UPDATE_ADOBE_TESTING = 'UPDATE_ADOBE_TESTING';
export const UPDATE_PC_CENSUS = 'UPDATE_PC_CENSUS';

export const TOGGLE_RETRIEVE_SESSION_MODAL = 'TOGGLE_RETRIEVE_SESSION_MODAL';
export const TOGGLE_RETRIEVE_WORK_MODAL = 'TOGGLE_RETRIEVE_WORK_MODAL';

export type DisplayAction =
    | ReturnType<typeof closeMenus>
    | ReturnType<typeof closeMobileNavMenu>
    | ReturnType<typeof closePhoneMenu>
    | ReturnType<typeof closeSubMenu>
    | ReturnType<typeof openMobileNavMenu>
    | ReturnType<typeof openPhoneMenu>
    | ReturnType<typeof openSubMenu>
    | ReturnType<typeof toggleSessionLookupResultsModal>
    | ReturnType<typeof toggleSimpleRetrieveModalOpen>
    | ReturnType<typeof toggleStickyEmailGuide>
    | ReturnType<typeof toggleZipFormModal>
    | ReturnType<typeof updateAdobeTesting>
    | ReturnType<typeof updatePcCensus>;

export const openMobileNavMenu = (): { type: 'OPEN_MOBILENAVMENU' } => {
    return {
        type: OPEN_MOBILENAVMENU,
    };
};

export const closeMobileNavMenu = (): { type: 'CLOSE_MOBILENAVMENU' } => {
    return {
        type: CLOSE_MOBILENAVMENU,
    };
};

export const openPhoneMenu = (): { type: 'OPEN_PHONEMENU' } => {
    return {
        type: OPEN_PHONEMENU,
    };
};

export const closePhoneMenu = (): { type: 'CLOSE_PHONEMENU' } => {
    return {
        type: CLOSE_PHONEMENU,
    };
};

export const openSubMenu = (menu: string): { type: 'OPEN_SUBMENU'; payload: string } => {
    return {
        type: OPEN_SUBMENU,
        payload: menu,
    };
};

export const closeSubMenu = (): { type: 'CLOSE_SUBMENU' } => {
    return {
        type: CLOSE_SUBMENU,
    };
};

export const closeMenus = (): { type: 'CLOSE_MENUS' } => {
    return {
        type: CLOSE_MENUS,
    };
};

export const toggleZipFormModal = (
    data: boolean
): { type: 'TOGGLE_ZIPMODAL'; payload: boolean } => {
    return {
        type: TOGGLE_ZIPMODAL,
        payload: data,
    };
};

export const toggleStickyEmailGuide = (
    data: boolean
): { type: 'TOGGLE_STICKY_EMAIL_GUIDE'; payload: boolean } => {
    return {
        type: TOGGLE_STICKY_EMAIL_GUIDE,
        payload: data,
    };
};

export const toggleSessionLookupResultsModal = (
    data: boolean
): { type: 'TOGGLE_RETRIEVE_SESSION_MODAL'; payload: boolean } => {
    return {
        type: TOGGLE_RETRIEVE_SESSION_MODAL,
        payload: data,
    };
};

export const toggleSimpleRetrieveModalOpen = (
    data: boolean
): { type: 'TOGGLE_RETRIEVE_WORK_MODAL'; payload: typeof data } => {
    return {
        type: TOGGLE_RETRIEVE_WORK_MODAL,
        payload: data,
    };
};

export const updateAdobeTesting = (
    data: AdobeTestingData
): { type: 'UPDATE_ADOBE_TESTING'; payload: typeof data } => {
    return {
        type: UPDATE_ADOBE_TESTING,
        payload: data,
    };
};

export const updatePcCensus = (
    data: PersonalCodeData
): { type: 'UPDATE_PC_CENSUS'; payload: typeof data } => {
    return {
        type: UPDATE_PC_CENSUS,
        payload: data,
    };
};
